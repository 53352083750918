import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import useTranslate from '../../../../../hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { AnimationType, setCreateReportFormState } from '../../../../../store/slices/create-report-form.slice';
import useActivityReport from '../../../../account/Dashboard/hooks/useActivityReport';

function CreateReportStep2() {
  const { t } = useTranslate();

  const dispatch = useAppDispatch();
  const {
    isImplementation,
    publicPlacesMeters,
    renovationMeters,
    accessMeters,
    isTraining,
    numberOfTrainedPeople,
    isAnimation,
    animationType,
    isPrescription,
    visitedDeciderId,
    animationAttendeeCount,
  } = useAppSelector(state => state.createReportForm);

  const decidersToVisit = useActivityReport().decidersToVisit;

  return (
    <Paper elevation={1}>
      <Box p={2}>
        <FormGroup>
          <FormLabel>{t('report.type')}</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={isImplementation}
                onChange={evt => dispatch(setCreateReportFormState({ isImplementation: evt.target.checked }))}
              />
            }
            label={t('report.type.implementation')}
          />
          {isImplementation && (
            <Stack direction="column" spacing={1}>
              <FormLabel>{t('report.number_of_meters')}</FormLabel>
              <TextField
                type="number"
                id="public-places-meters"
                label={t('market.public_places')}
                variant="outlined"
                placeholder="#m"
                value={publicPlacesMeters ?? ''}
                onChange={evt =>
                  dispatch(
                    setCreateReportFormState({
                      publicPlacesMeters: evt.target.value !== '' ? Number(evt.target.value) : null,
                    }),
                  )
                }
                required
              />
              <TextField
                type="number"
                id="renovation-meters"
                label={t('market.renovation')}
                variant="outlined"
                placeholder="#m"
                value={renovationMeters ?? ''}
                onChange={evt =>
                  dispatch(
                    setCreateReportFormState({
                      renovationMeters: evt.target.value !== '' ? Number(evt.target.value) : null,
                    }),
                  )
                }
                required
              />
              <TextField
                type="number"
                id="accesories-meters"
                label={t('market.access')}
                variant="outlined"
                placeholder="#m"
                value={accessMeters ?? ''}
                onChange={evt =>
                  dispatch(
                    setCreateReportFormState({
                      accessMeters: evt.target.value !== '' ? Number(evt.target.value) : null,
                    }),
                  )
                }
                required
              />
            </Stack>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={isTraining}
                onChange={evt => dispatch(setCreateReportFormState({ isTraining: evt.target.checked }))}
              />
            }
            label={t('report.type.training')}
          />
          {isTraining && (
            <TextField
              type="number"
              id="number-of-trained-people"
              label={t('report.number_of_trained_people')}
              variant="outlined"
              value={numberOfTrainedPeople ?? ''}
              onChange={evt =>
                dispatch(
                  setCreateReportFormState({
                    numberOfTrainedPeople: evt.target.value !== '' ? Math.round(Number(evt.target.value)) : null,
                  }),
                )
              }
              required
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={isAnimation}
                onChange={evt => {
                  dispatch(setCreateReportFormState({ isAnimation: evt.target.checked }));
                  if (evt.target.checked && animationType === null) {
                    dispatch(setCreateReportFormState({ animationType: AnimationType.SHOW }));
                  }
                }}
              />
            }
            label={t('report.type.animation')}
          />
          {isAnimation && (
            <>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="animation-type-label">{t('report.animation_type')}</InputLabel>
                <Select
                  labelId="animation-type-label"
                  id="animation-type"
                  value={animationType}
                  label="Age"
                  onChange={evt =>
                    dispatch(setCreateReportFormState({ animationType: evt.target.value as AnimationType }))
                  }>
                  <MenuItem value={AnimationType.SHOW}>{t('report.animation_type.show')}</MenuItem>
                  <MenuItem value={AnimationType.OPEN_DAY}>{t('report.animation_type.open_day')}</MenuItem>
                  <MenuItem value={AnimationType.ROAD_SHOW}>{t('report.animation_type.road_show')}</MenuItem>
                </Select>
              </FormControl>
              {(animationType === AnimationType.ROAD_SHOW || animationType === AnimationType.OPEN_DAY) && (
                <TextField
                  type="number"
                  id="animation-attendees-count"
                  label={t('report.animation.attendees_count')}
                  variant="outlined"
                  value={animationAttendeeCount ?? ''}
                  onChange={evt =>
                    dispatch(
                      setCreateReportFormState({
                        animationAttendeeCount: evt.target.value !== '' ? Math.round(Number(evt.target.value)) : null,
                      }),
                    )
                  }
                  required
                />
              )}
            </>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={isPrescription}
                onChange={evt => dispatch(setCreateReportFormState({ isPrescription: evt.target.checked }))}
              />
            }
            label={t('report.type.prescription')}
          />
          {isPrescription && (
            <>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="visited-decider-label">{t('report.prescription.visited_decider')}</InputLabel>
                <Select
                  labelId="visited-decider-label"
                  id="visited-decider"
                  value={visitedDeciderId}
                  label={t('report.prescription.visited_decider')}
                  onChange={evt => dispatch(setCreateReportFormState({ visitedDeciderId: evt.target.value }))}>
                  {decidersToVisit.map(decider => (
                    <MenuItem key={decider.deciderId} value={decider.deciderId}>
                      {decider.firstName + ' ' + decider.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </FormGroup>
      </Box>
    </Paper>
  );
}

export default CreateReportStep2;
