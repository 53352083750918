import { useEffect, useState } from 'react';
import Year from '../../../../utils/year';
import {
  BenchmarkProposalStatus,
  useGetBenchmarkProposalsQuery,
} from '../../../../services/benchmark-proposal.service';
import {
  useGetActivityRepartitionQuery,
  useGetAnimationDataQuery,
  useGetCoverageRateQuery,
  useGetDecidersToVisitQuery,
  useGetGroomingQuery,
  useGetNumberOfImplantedMetersQuery,
  useGetTrainingDataQuery,
  useGetVisitedDecidersQuery,
} from '../../../../services/intervention-report.service';
import { UserRole, useGetMySubordinatesQuery, useGetObjectivesQuery } from '../../../../services/user.service';
import { getSubordinatesByRole } from '../../../../helpers/user';

/** A hook to help with the activity report section of the dashboard. */
function useActivityReport() {
  const [availableYears, setAvailableYears] = useState<number[]>([]);

  const [page, setPage] = useState<number>(1);

  const currentDate = new Date();

  const [query, setQuery] = useState<string>('');
  const [year, setYear] = useState<number>(currentDate.getFullYear());
  const [month, setMonth] = useState<number>(currentDate.getMonth() + 1);
  const [userId, setUserId] = useState<number>();
  const [state, setState] = useState<BenchmarkProposalStatus | 'all'>('all');
  const [group, setGroup] = useState<string | 'all'>('all');
  const [entity, setEntity] = useState<string | 'all'>('all');

  const { data: benchmarkProposals } = useGetBenchmarkProposalsQuery({
    page,
    filters: {
      wholesalerQuery: query.trim() || undefined,
      year: year,
      month: month,
      userId: userId,
      state: state === 'all' ? undefined : state,
      group: group === 'all' ? undefined : group,
      entity: entity === 'all' ? undefined : entity,
    },
  });

  const { data: numberOfImplantedMeters } = useGetNumberOfImplantedMetersQuery({
    year: year,
    month: month,
    userId: userId,
    state: state === 'all' ? undefined : state,
    group: group === 'all' ? undefined : group,
    entity: entity === 'all' ? undefined : entity,
    wholesalerQuery: query.trim() || undefined,
  });

  const { data: subordinates } = useGetMySubordinatesQuery();

  const { data: activityRepartition } = useGetActivityRepartitionQuery({
    year: year,
    month: month,
    userId: userId,
  });

  const { data: coverageRate } = useGetCoverageRateQuery({
    year: year,
    month: month,
    userId: userId,
  });

  const { data: grooming } = useGetGroomingQuery({
    year: year,
    userId: userId,
  });

  const { data: decidersToVisit } = useGetDecidersToVisitQuery({
    userId: userId,
  });

  const { data: visitedDeciders } = useGetVisitedDecidersQuery({
    year: year,
    month: month,
    userId: userId,
  });

  const { data: trainingData } = useGetTrainingDataQuery({
    year: year,
    month: month,
    userId: userId,
  });

  const { data: animationData } = useGetAnimationDataQuery({
    year: year,
    month: month,
    userId: userId,
  });

  // Generate the available years.
  useEffect(() => {
    const currentYear = Year.current();
    setAvailableYears(currentYear.getLastYears(10));
  }, []);

  return {
    year,
    month,
    userId,
    availableYears,
    pageInfo: benchmarkProposals?.pageInfo,
    numberOfImplantedMeters: numberOfImplantedMeters ?? { total: 0, detail: {} },
    activityRepartition: activityRepartition ?? 0,
    coverageRate: coverageRate ?? 0,
    grooming: grooming ?? 0,
    decidersToVisit: decidersToVisit ?? [],
    visitedDeciders: visitedDeciders ?? 0,
    trainingData: trainingData ?? { numberOfTrainings: 0, numberOfPeopleTrained: 0 },
    animationData: animationData ?? { numberOfAnimations: 0, numberOfAnimationAttendees: 0 },
    salesCoordinators: getSubordinatesByRole(UserRole.SALES_COORDINATOR, subordinates ?? []),
    salesManagers: getSubordinatesByRole(UserRole.SALES_MANAGER, subordinates ?? []),
    setPage,
    setQuery,
    setYear,
    setMonth,
    setUserId,
    setState,
    setGroup,
    setEntity,
  };
}

export default useActivityReport;
