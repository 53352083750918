import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Config from '../config';
import { prepareHeaders } from '../helpers/services';
import { Wholesaler } from './wholesaler.service';
import { UserSummary } from './user.service';
import { Product } from './product.service';
import { BenchmarkProposalStatus } from './benchmark-proposal.service';
import { AnimationType } from '../store/slices/create-report-form.slice';

export interface CreateProductStatementDto {
  productRef: string;
  quantity: number;
}

export interface CreateInterventionReportDto {
  benchmarkProposalId?: number;
  wholesalerId: string;
  photoKeys: string[];
  products: CreateProductStatementDto[];
  isImplementation: boolean;
  publicPlacesMeters: number | null;
  renovationMeters: number | null;
  accessMeters: number | null;
  isTraining: boolean;
  numberOfTrainedPeople: number | null;
  isAnimation: boolean;
  animationType: AnimationType | null;
  isPrescription: boolean;
  visitedDeciderId: string | null;
  animationAttendeeCount: number | null;
  comments?: string;
}

export interface InterventionReportPhoto {
  interventionReportId: number;
  key: string;
}

export interface InterventionReportProduct {
  interventionReportId: number;
  product: Product;
  quantity: number;
}

export interface InterventionReportSummary {
  id: number;
  benchmarkProposal: {
    id: number;
  };
  wholesaler: Wholesaler;
  coordinator: UserSummary;
  isImplementation: boolean;
  isTraining: boolean;
  numberOfTrainedPeople: number | null;
  isAnimation: boolean;
  animationType: AnimationType | null;
  isPrescription: boolean;
  visitedDeciderId: string | null;
  animationAttendeeCount: number | null;
  createdAt: string;
  updatedAt: string;
}

export interface InterventionReportSummaryWithSales extends InterventionReportSummary {
  sales: UserSummary | null;
}

export interface InterventionReport {
  id: number;
  benchmarkProposal: {
    id: number;
  };
  wholesaler: Wholesaler;
  coordinator: UserSummary;
  photos: InterventionReportPhoto[];
  products: InterventionReportProduct[];
  isImplementation: boolean;
  publicPlacesMeters: number | null;
  renovationMeters: number | null;
  accessMeters: number | null;
  isTraining: boolean;
  numberOfTrainedPeople: number | null;
  isAnimation: boolean;
  animationType: AnimationType | null;
  isPrescription: boolean;
  visitedDeciderId: string | null;
  animationAttendeeCount: number | null;
  comments: string;
  createdAt: string;
  updatedAt: string;
}

export interface InterventionReportWithSales extends InterventionReport {
  sales: UserSummary | null;
}

export interface GetAllOrphanReportsFilters {
  wholesalerId?: string;
  userId?: number;
  group?: string;
  entity?: string;
  wholesalerQuery?: string;
}

export interface GetNumberOfImplantedMetersFilters {
  year?: number;
  month?: number;
  userId?: number;
  state?: BenchmarkProposalStatus;
  group?: string;
  entity?: string;
  wholesalerQuery?: string;
}

export interface GetActivityRepartitionFilters {
  year?: number;
  month?: number;
  userId?: number;
}

export interface GetCoverageRateFilters {
  year?: number;
  month?: number;
  userId?: number;
}

export interface GetGroomingFilters {
  year?: number;
  userId?: number;
}

export interface GetDecidersToVisitFilters {
  userId?: number;
}

export interface GetVisitedDecidersFilters {
  year?: number;
  month?: number;
  userId?: number;
}

export interface GetTrainingDataFilters {
  year?: number;
  month?: number;
  userId?: number;
}

export interface GetAnimationDataFilters {
  year?: number;
  month?: number;
  userId?: number;
}

export interface CountReportsFilters {
  year?: number;
  month?: number;
  userId?: number;
  state?: BenchmarkProposalStatus;
  group?: string;
  entity?: string;
  wholesalerQuery?: string;
}

export interface GetVisitReportsFilters {
  coordinatorId?: number;
  startDate?: Date;
  endDate?: Date;
}

export const interventionReportApi = createApi({
  reducerPath: 'interventionReportApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${Config.apiBaseUrl}/intervention-report`, prepareHeaders }),
  endpoints: builder => ({
    getReportById: builder.query<InterventionReportWithSales, number | string>({
      query: id => `/${id}`,
    }),

    /**
     * Get all orphans intervention reports.
     * @returns The list of orphans intervention reports.
     */
    getAllOrphansReports: builder.query<InterventionReportSummaryWithSales[], GetAllOrphanReportsFilters | void>({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.wholesalerId) searchParams.append('wholesalerId', params.wholesalerId);
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        if (params?.group) searchParams.append('group', params.group);
        if (params?.entity) searchParams.append('entity', params.entity);
        if (params?.wholesalerQuery) searchParams.append('wholesalerQuery', params.wholesalerQuery);
        return `/orphans?${searchParams.toString()}`;
      },
    }),

    /**
     * Get all visit reports.
     * @returns The list of visit reports.
     */
    getAllVisitReports: builder.query<InterventionReportSummary[], GetVisitReportsFilters | void>({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.coordinatorId) searchParams.append('coordinatorId', params.coordinatorId.toString());
        if (params?.startDate) searchParams.append('startDate', params.startDate.toISOString());
        if (params?.endDate) searchParams.append('endDate', params.endDate.toISOString());
        return `/visits?${searchParams.toString()}`;
      },
    }),

    /**
     * Get a signed URL to upload a photo to S3.
     * @param mimeType The MIME type of the photo.
     * @returns The signed URL and the S3 key.
     */
    getReportUploadPhotoUrl: builder.query<{ signedUrl: string; key: string }, string>({
      query: (mimeType: string) => `/photo/upload-url?mimeType=${encodeURIComponent(mimeType)}`,
    }),

    /**
     * Get the number of implanted meters for a user's zone.
     * @param params The filters to apply.
     */
    getNumberOfImplantedMeters: builder.query<
      { total: number; detail: { [key: string]: number } },
      GetNumberOfImplantedMetersFilters | void
    >({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.year) searchParams.append('year', params.year.toString());
        if (params?.month) searchParams.append('month', (params.month - 1).toString());
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        if (params?.state) searchParams.append('state', params.state);
        if (params?.group) searchParams.append('group', params.group);
        if (params?.entity) searchParams.append('entity', params.entity);
        if (params?.wholesalerQuery) searchParams.append('wholesalerQuery', params.wholesalerQuery);
        return `/number-of-implanted-meters?${searchParams.toString()}`;
      },
    }),

    /**
     * Get the number of reports for a user's zone.
     * @param params The filters to apply.
     */
    countReports: builder.query<number, CountReportsFilters | void>({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.year) searchParams.append('year', params.year.toString());
        if (params?.month) searchParams.append('month', (params.month - 1).toString());
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        if (params?.state) searchParams.append('state', params.state);
        if (params?.group) searchParams.append('group', params.group);
        if (params?.entity) searchParams.append('entity', params.entity);
        if (params?.wholesalerQuery) searchParams.append('wholesalerQuery', params.wholesalerQuery);
        return `/count?${searchParams.toString()}`;
      },
    }),

    /**
     * Get the activity repartition for an animator
     */

    getActivityRepartition: builder.query<number, GetActivityRepartitionFilters | void>({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.year) searchParams.append('year', params.year.toString());
        if (params?.month) searchParams.append('month', (params.month - 1).toString());
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        return `/activity-repartition?${searchParams.toString()}`;
      },
    }),

    getCoverageRate: builder.query<number, GetCoverageRateFilters>({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.year) searchParams.append('year', params.year.toString());
        if (params?.month) searchParams.append('month', (params.month - 1).toString());
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        return `/coverage-rate?${searchParams.toString()}`;
      },
    }),

    getGrooming: builder.query<number, GetGroomingFilters>({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.year) searchParams.append('year', params.year.toString());
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        return `/grooming?${searchParams.toString()}`;
      },
    }),

    getDecidersToVisit: builder.query<
      Array<{ deciderId: string; firstName: string; lastName: string }>,
      GetDecidersToVisitFilters
    >({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        return `/deciders-to-visit?${searchParams.toString()}`;
      },
    }),

    getVisitedDeciders: builder.query<number, GetVisitedDecidersFilters>({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.year) searchParams.append('year', params.year.toString());
        if (params?.month) searchParams.append('month', (params.month - 1).toString());
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        return `/visited-deciders?${searchParams.toString()}`;
      },
    }),

    getTrainingData: builder.query<
      { numberOfTrainings: number; numberOfPeopleTrained: number },
      GetTrainingDataFilters
    >({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.year) searchParams.append('year', params.year.toString());
        if (params?.month) searchParams.append('month', (params.month - 1).toString());
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        return `/training-data?${searchParams.toString()}`;
      },
    }),

    getAnimationData: builder.query<
      { numberOfAnimations: number; numberOfAnimationAttendees: number },
      GetAnimationDataFilters
    >({
      query: params => {
        const searchParams = new URLSearchParams();
        if (params?.year) searchParams.append('year', params.year.toString());
        if (params?.month) searchParams.append('month', (params.month - 1).toString());
        if (params?.userId) searchParams.append('userId', params.userId.toString());
        return `/animation-data?${searchParams.toString()}`;
      },
    }),

    /**
     * Create an intervention report.
     * @param params The data of the intervention report.
     * @returns The created intervention report.
     */
    createInterventionReport: builder.mutation<InterventionReport, CreateInterventionReportDto>({
      query: params => ({
        url: '/',
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  useGetReportByIdQuery,
  useGetAllOrphansReportsQuery,
  useLazyGetReportUploadPhotoUrlQuery,
  useGetAllVisitReportsQuery,
  useLazyGetAllVisitReportsQuery,
  useGetNumberOfImplantedMetersQuery,
  useCountReportsQuery,
  useCreateInterventionReportMutation,
  useGetActivityRepartitionQuery,
  useGetCoverageRateQuery,
  useGetGroomingQuery,
  useGetDecidersToVisitQuery,
  useGetVisitedDecidersQuery,
  useGetTrainingDataQuery,
  useGetAnimationDataQuery,
} = interventionReportApi;
