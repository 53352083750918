import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import useTranslate from '../../../../hooks/useTranslate';
import { useGetObjectivesQuery, UserRole } from '../../../../services/user.service';
import { useAppSelector } from '../../../../store';
import { getFullName } from '../../../../helpers/user';
import useNetwork from '../../../../hooks/useNetwork';
import useActivityReport from '../hooks/useActivityReport';
import { pdf } from '@react-pdf/renderer';
import ActivityReportRecap, { ActivityReport } from '../../../../components/benchmark-proposal/pdf/ActivityReportRecap';
import ActivityReportDataCard from './ActivityReportDataCard';

export interface ActivityMonitoringCardProps {
  showTitle?: boolean;
}

function ActivityReportCard({ showTitle = true }: ActivityMonitoringCardProps) {
  const { online } = useNetwork();

  const { user } = useAppSelector(state => state.auth);

  const { t, localeNumber } = useTranslate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const {
    year,
    month,
    userId,
    availableYears,
    pageInfo,
    numberOfImplantedMeters,
    activityRepartition,
    coverageRate,
    grooming,
    visitedDeciders,
    trainingData,
    animationData,
    salesCoordinators,
    setPage,
    setYear,
    setMonth,
    setUserId,
  } = useActivityReport();

  if (user?.role !== UserRole.SALES_COORDINATOR && userId === undefined) {
    setUserId(salesCoordinators[0].id);
  }

  let productivity = numberOfImplantedMeters.total;

  async function downloadRecapAsPdf() {
    const coordinatorMap: Record<number, string> = salesCoordinators.reduce(
      (acc, coordinator) => ({
        ...acc,
        [coordinator.id]: `${coordinator.firstName}_${coordinator.lastName}`,
      }),
      {},
    );

    const coordinatorName = coordinatorMap[isCoordinator ? user.id : (userId as number)] ?? 'Inconnu';

    const monthMap: Record<number, string> = {
      1: t('global.month.january'),
      2: t('global.month.february'),
      3: t('global.month.march'),
      4: t('global.month.april'),
      5: t('global.month.may'),
      6: t('global.month.june'),
      7: t('global.month.july'),
      8: t('global.month.august'),
      9: t('global.month.september'),
      10: t('global.month.october'),
      11: t('global.month.november'),
      12: t('global.month.december'),
    };

    const report: ActivityReport = {
      coordinatorName: coordinatorName,
      reportDate: { year: year.toString(), month: monthMap[month] },
      productivity: { current: productivity, objective: productivityObjective },
      activityRepartition: { current: activityRepartition, objective: activityRepartitionObjective },
      coverageRate: { current: coverageRate, objective: coverageRateObjective },
      grooming: { current: grooming, objective: groomingObjective },
      visitedDeciders: { current: visitedDeciders, objective: visitedDecidersObjective },
      trainings: { current: trainingData.numberOfTrainings, objective: trainingsObjective },
      trainingAttendees: { current: trainingData.numberOfPeopleTrained, objective: trainingAttendeesObjective },
      animations: { current: animationData.numberOfAnimations, objective: animationsObjective },
      animationAttendees: { current: animationData.numberOfAnimationAttendees, objective: animationAttendeesObjective },
    };
    const blob = await pdf(ActivityReportRecap(report)).toBlob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.download = `bilan_mensuel_activite_${coordinatorName}_${year}_${month}.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  const userObjectives =
    useGetObjectivesQuery({
      userId: user?.role === UserRole.SALES_COORDINATOR ? user.id : salesCoordinators[0].id,
      year: year ?? new Date().getFullYear(),
    }).data ?? {};

  const productivityObjective = userObjectives.productivity ?? undefined;
  const activityRepartitionObjective = userObjectives.activity_repartition ?? undefined;
  const coverageRateObjective = userObjectives.coverage_rate ?? undefined;
  const groomingObjective = userObjectives.grooming ?? undefined;
  const visitedDecidersObjective = userObjectives.visited_deciders ?? undefined;
  const trainingsObjective = userObjectives.trainings ?? undefined;
  const trainingAttendeesObjective = userObjectives.training_attendees ?? undefined;
  const animationsObjective = userObjectives.animations ?? undefined;
  const animationAttendeesObjective = userObjectives.animation_attendees ?? undefined;

  const isSales = user?.role === UserRole.SALES;
  const isCoordinator = user?.role === UserRole.SALES_COORDINATOR;
  const isManagerOrDirector = user?.role === UserRole.SALES_MANAGER || user?.role === UserRole.SALES_DIRECTOR;

  if (!online) {
    return null;
  }

  return (
    <Stack spacing={2} mb={2} id="activity-report">
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" onClick={downloadRecapAsPdf}>
          {t('global.export_pdf')}
        </Button>
      </Box>
      <Card variant="outlined">
        <Stack direction="column" spacing={2} mb={2}>
          <Stack direction={isTablet ? 'column' : 'row'} spacing={2} my={2}>
            <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
              <InputLabel id="year-select-label">{t('global.year')}</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                value={year}
                label={t('global.year')}
                onChange={evt => setYear(Number(evt.target.value))}>
                {availableYears.map(year => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ minWidth: 100 }} fullWidth>
              <InputLabel id="month-select-label">{t('global.month')}</InputLabel>
              <Select
                labelId="month-select-label"
                id="month-select"
                value={month}
                label={t('global.month')}
                onChange={evt => setMonth(Number(evt.target.value))}>
                <MenuItem value={1}>{t('global.month.january')}</MenuItem>
                <MenuItem value={2}>{t('global.month.february')}</MenuItem>
                <MenuItem value={3}>{t('global.month.march')}</MenuItem>
                <MenuItem value={4}>{t('global.month.april')}</MenuItem>
                <MenuItem value={5}>{t('global.month.may')}</MenuItem>
                <MenuItem value={6}>{t('global.month.june')}</MenuItem>
                <MenuItem value={7}>{t('global.month.july')}</MenuItem>
                <MenuItem value={8}>{t('global.month.august')}</MenuItem>
                <MenuItem value={9}>{t('global.month.september')}</MenuItem>
                <MenuItem value={10}>{t('global.month.october')}</MenuItem>
                <MenuItem value={11}>{t('global.month.november')}</MenuItem>
                <MenuItem value={12}>{t('global.month.december')}</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={isTablet ? 'column' : 'row'} spacing={2} my={2}>
            {/* User filter for all roles except sales */}
            {!isSales && !isCoordinator && (
              <FormControl size="small" sx={{ minWidth: 200 }} fullWidth>
                <InputLabel id="user-select-label">{t('user.title')}</InputLabel>
                <Select
                  labelId="user-select-label"
                  id="sales-select"
                  value={salesCoordinators[0].id}
                  label={t('user.title')}
                  onChange={evt => setUserId(evt.target.value as number)}>
                  {/* Directors and managers can filter on coordinators */}
                  {isManagerOrDirector && salesCoordinators.length > 0 && (
                    <ListSubheader>{t('user.role.sales_coordinator_plurial')}</ListSubheader>
                  )}
                  {isManagerOrDirector &&
                    salesCoordinators.map(sale => (
                      <MenuItem key={sale.id} value={sale.id}>
                        {getFullName(sale)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </Stack>
      </Card>
      <Card variant="outlined">
        {showTitle && (
          <Typography variant="h6" component="h2" fontWeight="regular" mb={2}>
            {t('dashboard.monthly_activity_report.merchandising_section')}
          </Typography>
        )}

        <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mb={2}>
          <ActivityReportDataCard
            title={t('dashboard.monthly_activity_report.merchandising_section.productivity')}
            currentValue={productivity}
            objectiveValue={productivityObjective}
          />
          <ActivityReportDataCard
            title={t('dashboard.monthly_activity_report.merchandising_section.activity')}
            currentValue={activityRepartition}
            objectiveValue={activityRepartitionObjective}
            unit="%"
          />
          <ActivityReportDataCard
            title={t('dashboard.monthly_activity_report.merchandising_section.coverage')}
            currentValue={coverageRate}
            objectiveValue={coverageRateObjective}
            unit="%"
          />
          <ActivityReportDataCard
            title={t('dashboard.monthly_activity_report.merchandising_section.grooming')}
            currentValue={grooming}
            objectiveValue={groomingObjective}
            unit="%"
          />
        </Stack>

        {(pageInfo?.numberOfPages ?? 0) > 1 && (
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Pagination count={pageInfo?.numberOfPages} color="primary" onChange={(_, value) => setPage(value)} />
          </Box>
        )}
      </Card>

      <Card variant="outlined">
        {showTitle && (
          <Typography variant="h6" component="h2" fontWeight="regular" mb={2}>
            {t('dashboard.monthly_activity_report.business_activity_section')}
          </Typography>
        )}

        <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mb={2}>
          <ActivityReportDataCard
            title={t('dashboard.monthly_activity_report.business_activity_section.visited_deciders')}
            currentValue={visitedDeciders}
            objectiveValue={visitedDecidersObjective}
            unit="%"
          />
          <ActivityReportDataCard
            title={t('dashboard.monthly_activity_report.business_activity_section.trainings')}
            currentValue={trainingData.numberOfTrainings}
            objectiveValue={trainingsObjective}
          />
          <ActivityReportDataCard
            title={t('dashboard.monthly_activity_report.business_activity_section.trainees')}
            currentValue={trainingData.numberOfPeopleTrained}
            objectiveValue={trainingAttendeesObjective}
          />
          <ActivityReportDataCard
            title={t('dashboard.monthly_activity_report.business_activity_section.jpo_and_roadshows')}
            currentValue={animationData.numberOfAnimations}
            objectiveValue={animationsObjective}
          />
          <ActivityReportDataCard
            title={t('dashboard.monthly_activity_report.business_activity_section.attendees_jpo_and_roadshows')}
            currentValue={animationData.numberOfAnimationAttendees}
            objectiveValue={animationAttendeesObjective}
          />
        </Stack>

        {(pageInfo?.numberOfPages ?? 0) > 1 && (
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Pagination count={pageInfo?.numberOfPages} color="primary" onChange={(_, value) => setPage(value)} />
          </Box>
        )}
      </Card>
    </Stack>
  );
}

export default ActivityReportCard;
