import { Paper, Typography } from "@mui/material";
import useTranslate from "../../../../hooks/useTranslate";

export interface ActivityReportDataCardProps {
  title: string;
  currentValue: number;
  objectiveValue: number;
  unit?: string;
}

function ActivityReportDataCard({ title, currentValue, objectiveValue, unit = '' }: ActivityReportDataCardProps) {
  const { t, localeNumber } = useTranslate();

  const isObjectiveDefined = typeof objectiveValue === 'number';

  return (
    <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2, flexGrow: 1 }}>
      <Typography variant="subtitle1" color="text.primary">
        {title}
      </Typography>
      <Typography variant="h6" color="text.primary">
        {`${localeNumber(currentValue)}${unit}` + (isObjectiveDefined ? ` / ${localeNumber(objectiveValue)}${unit}` : '')}
      </Typography>
      {isObjectiveDefined ? (
        <Typography
          variant="body2"
          color={currentValue > objectiveValue ? 'success.main' : 'error.main'}
          sx={{ display: 'flex', alignItems: 'center' }}>
          {currentValue > objectiveValue ? '+' : ''}
          {localeNumber((100 * (currentValue - objectiveValue)) / objectiveValue)}%
        </Typography>
      ) : (
        <Typography variant="body2" color="text.secondary">
          {t('dashboard.monthly_activity_report.no_objective')}
        </Typography>
      )}
    </Paper>
  );
}

export default ActivityReportDataCard;