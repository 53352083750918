import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { AnimationType, setCreateReportFormState } from '../../../../store/slices/create-report-form.slice';
import { boundNumber } from '../../../../utils/number';

/** The minimum step number of the form. */
export const MIN_CREATE_REPORT_FORM_STEP = 1;

/** The maximum step number of the form. */
export const MAX_CREATE_REPORT_FORM_STEP = 5;

/** The step number of the intervention step. */
export const REPORT_INTERVENTION_FORM_STEP = 2;

/** The step number of the scanner step. */
export const CREATE_REPORT_FORM_SCAN_STEP = 3;

/** A hook for managing the create report form. */
function useCreateReportForm() {
  const dispatch = useAppDispatch();
  const createReportFormState = useAppSelector(state => state.createReportForm);

  const [isCurrentStepValid, setIsCurrentStepValid] = useState<boolean>(true);

  /**
   * Go to a specific step.
   * @param step The step to go to.
   */
  function goToStep(step: number) {
    const validStep = boundNumber(step, MIN_CREATE_REPORT_FORM_STEP, MAX_CREATE_REPORT_FORM_STEP);
    if (validStep === createReportFormState.step) return;

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('step', String(validStep));
    window.location.search = urlParams.toString();
  }

  /**
   * Validate the first step of the form.
   * @returns Whether the first step of the form is valid.
   */
  function validateStep1(): boolean {
    if (!createReportFormState.wholesalerId) {
      return false;
    }

    return true;
  }

  /**
   * Validate the second step of the form.
   * @returns Whether the second step of the form is valid.
   */
  function validateStep2(): boolean {
    if (createReportFormState.isImplementation) {
      if (createReportFormState.publicPlacesMeters === null) {
        return false;
      }
      if (createReportFormState.renovationMeters === null) {
        return false;
      }
      if (createReportFormState.accessMeters === null) {
        return false;
      }
    }

    if (createReportFormState.isTraining) {
      if (createReportFormState.numberOfTrainedPeople === null) {
        return false;
      }
    }

    if (createReportFormState.isAnimation) {
      if (createReportFormState.animationType === null) {
        return false;
      } else if (
        createReportFormState.animationType === AnimationType.ROAD_SHOW ||
        createReportFormState.animationType === AnimationType.OPEN_DAY
      ) {
        if (createReportFormState.animationAttendeeCount === null) {
          return false;
        }
      }
    }
    return true;
  }

  /** Clean the current step to avoid invalid data. */
  function cleanCurrentStep() {
    if (createReportFormState.step === 2) {
      if (!createReportFormState.isImplementation) {
        dispatch(
          setCreateReportFormState({
            publicPlacesMeters: null,
            renovationMeters: null,
            accessMeters: null,
          }),
        );
      }

      if (!createReportFormState.isTraining) {
        dispatch(setCreateReportFormState({ numberOfTrainedPeople: null }));
      }

      if (!createReportFormState.isAnimation) {
        dispatch(setCreateReportFormState({ animationType: null }));
        dispatch(setCreateReportFormState({ animationAttendeeCount: null }));
      }
    }
  }

  /**
   * Get the step from the URL.
   * @returns The step from the URL or `null` if there is no step in the URL.
   */
  function getStepFromUrl(): number | null {
    const params = new URLSearchParams(window.location.search);
    if (params.has('step')) {
      return boundNumber(Number(params.get('step')), MIN_CREATE_REPORT_FORM_STEP, MAX_CREATE_REPORT_FORM_STEP);
    }

    return null;
  }

  // Update the step in the URL when the step changes.
  useEffect(() => {
    const stepFromUrl = getStepFromUrl();
    dispatch(setCreateReportFormState({ step: stepFromUrl ?? MIN_CREATE_REPORT_FORM_STEP }));
  }, []);

  // Update the form validity when the inputs change.
  useEffect(() => {
    if (createReportFormState.step === 1) {
      setIsCurrentStepValid(validateStep1());
    } else if (createReportFormState.step === 2) {
      setIsCurrentStepValid(validateStep2());
    } else {
      setIsCurrentStepValid(true);
    }
  }, [createReportFormState]);

  return {
    /** Whether the current step is valid. */
    isCurrentStepValid,
    cleanCurrentStep,
    goToStep,
  };
}

export default useCreateReportForm;
