import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { MIN_CREATE_REPORT_FORM_STEP } from '../../pages/intervention-report/create/hooks/useCreateReportForm';
import { ProductStatement } from '../types';
import { FileBase64 } from '../../helpers/photos';

/** A type of animation. */
export enum AnimationType {
  /** An animation at a show. */
  SHOW = 'show',
  /**  An animation at an open day. */
  OPEN_DAY = 'open_day',
  /** An animation at a road show. */
  ROAD_SHOW = 'road_show',
}

/**
 * Redux state for the create report form.
 * A report is made for a wholesaler to track an intervention made in their store.
 */
export interface CreateReportFormState {
  /** The current step of the form. */
  step: number;
  /** The last time the form was updated. */
  updatedAt: string;

  /* --- Step 1 --- */
  /** The wholesaler id. */
  wholesalerId: string | null;
  /** The benchmark proposal id. */
  benchmarkProposalId: number | null;

  /* --- Step 2 --- */
  /** Is the report for an implementation? */
  isImplementation: boolean;
  /** The number of linear meters for the public places market section, if the report is an implementation. */
  publicPlacesMeters: number | null;
  /** The number of linear meters for the renovation market section, if the report is an implementation. */
  renovationMeters: number | null;
  /** The number of linear meters for the new construction market section, if the report is an implementation. */
  accessMeters: number | null;
  /** Is the report for a training? */
  isTraining: boolean;
  /** The number of people trained during the training, if the report is a training. */
  numberOfTrainedPeople: number | null;
  /** Is the report for an animation? */
  isAnimation: boolean;
  /** The type of animation, if the report is an animation. */
  animationType: AnimationType | null;
  /** Is the report for a prescription? */
  isPrescription: boolean;
  /** The id of the decider, if the report is a prescription. */
  visitedDeciderId: string | null;
  /** The number of attendees to an animation */
  animationAttendeeCount: number | null;

  /* --- Step 3 --- */
  /** The products statements available from the wholesaler. */
  productStatements: ProductStatement[];

  /* --- Step 4 --- */
  // Nothing needed for this step.

  /* --- Step 5 --- */
  /** A list photos for this report. */
  photos: FileBase64[];
  /** Comments from the coordinator for the report. */
  comments: string;
}

/** The initial state for the create report form slice. */
const initialState: CreateReportFormState = {
  step: MIN_CREATE_REPORT_FORM_STEP,
  updatedAt: new Date().toISOString(),
  wholesalerId: null,
  benchmarkProposalId: null,
  isImplementation: true,
  publicPlacesMeters: null,
  renovationMeters: null,
  accessMeters: null,
  isTraining: false,
  numberOfTrainedPeople: null,
  isAnimation: false,
  animationType: null,
  isPrescription: false,
  visitedDeciderId: null,
  animationAttendeeCount: null,
  productStatements: [],
  photos: [],
  comments: '',
};

export const createReportFormSlice = createSlice({
  name: 'createReportForm',
  initialState,
  reducers: {
    /** Set the create report form state. */
    setCreateReportFormState(state, action: PayloadAction<Partial<CreateReportFormState>>) {
      return {
        ...state,
        ...action.payload,
        updatedAt: new Date().toISOString(),
      };
    },

    /** Add a photo to the create report form state. */
    addPhoto(state, action: PayloadAction<FileBase64>) {
      state.photos.push(action.payload);
      state.updatedAt = new Date().toISOString();
    },

    /** Remove a photo from the create report form state. */
    removePhoto(state, action: PayloadAction<number>) {
      state.photos.splice(action.payload, 1);
      state.updatedAt = new Date().toISOString();
    },

    /** Reset the create report form state. */
    resetCreateReportFormState() {
      return initialState;
    },
  },
});

export const { setCreateReportFormState, addPhoto, removePhoto, resetCreateReportFormState } =
  createReportFormSlice.actions;

export default createReportFormSlice.reducer;
