import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import useTranslate from '../../../hooks/useTranslate';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
    { src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 700 },
  ],
});

export interface ActivityReport {
  coordinatorName: string;
  reportDate: { year: string; month: string };
  productivity: { current: number; objective: number };
  activityRepartition: { current: number; objective: number };
  coverageRate: { current: number; objective: number };
  grooming: { current: number; objective: number };
  visitedDeciders: { current: number; objective: number };
  trainings: { current: number; objective: number };
  trainingAttendees: { current: number; objective: number };
  animations: { current: number; objective: number };
  animationAttendees: { current: number; objective: number };
}

function formatMetric(value: number, objective: number) {
  return value + ' / ' + objective;
}

function ActivityReportRecap(report: ActivityReport) {
  const { t, localeDate } = useTranslate();

  return (
    <Document title="Bilan mensuel d'activité">
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={(styles.titleContainer, { gap: 30 })}>
          <Text style={styles.title}>
            {report.coordinatorName.replace(/_/g, ' ') + ` | ${report.reportDate.month} ${report.reportDate.year}`}
          </Text>
          <Text style={styles.subtitle}>
            {t('statement.date', { date: new Date().toLocaleDateString() })}
            {'\n\n\n'}
          </Text>
        </View>
        <View style={[styles.infoTable, { gap: 30 }]}>
          {/* First row */}
          <View style={styles.infoTableRow}>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>
                {t('dashboard.monthly_activity_report.merchandising_section.productivity')}
              </Text>
              <Text style={styles.infoTableCell}>
                {formatMetric(report.productivity.current, report.productivity.objective)}
              </Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>
                {t('dashboard.monthly_activity_report.merchandising_section.activity')}
              </Text>
              <Text style={styles.infoTableCell}>
                {formatMetric(report.activityRepartition.current, report.activityRepartition.objective)}
              </Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>
                {t('dashboard.monthly_activity_report.merchandising_section.coverage')}
              </Text>
              <Text style={styles.infoTableCell}>
                {formatMetric(report.coverageRate.current, report.coverageRate.objective)}
              </Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>
                {t('dashboard.monthly_activity_report.merchandising_section.grooming')}
              </Text>
              <Text style={styles.infoTableCell}>
                {formatMetric(report.grooming.current, report.grooming.objective)}
              </Text>
            </View>
          </View>
          {/* Second row */}
          <View style={styles.infoTableRow}>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>
                {t('dashboard.monthly_activity_report.business_activity_section.visited_deciders')}
              </Text>
              <Text style={styles.infoTableCell}>
                {formatMetric(report.visitedDeciders.current, report.visitedDeciders.objective)}
              </Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>
                {t('dashboard.monthly_activity_report.business_activity_section.trainings')}
              </Text>
              <Text style={styles.infoTableCell}>
                {formatMetric(report.trainings.current, report.trainings.objective)}
              </Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>
                {t('dashboard.monthly_activity_report.business_activity_section.trainees')}
              </Text>
              <Text style={styles.infoTableCell}>
                {formatMetric(report.trainingAttendees.current, report.trainingAttendees.objective)}
              </Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>
                {t('dashboard.monthly_activity_report.business_activity_section.jpo_and_roadshows')}
              </Text>
              <Text style={styles.infoTableCell}>
                {formatMetric(report.animations.current, report.animations.objective)}
              </Text>
            </View>
            <View style={styles.infoTable}>
              <Text style={styles.infoTableRowHeader}>
                {t('dashboard.monthly_activity_report.business_activity_section.attendees_jpo_and_roadshows')}
              </Text>
              <Text style={styles.infoTableCell}>
                {formatMetric(report.animationAttendees.current, report.animationAttendees.objective)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Roboto',
  },
  titleContainer: {
    marginTop: 5,
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 14,
    textAlign: 'center',
    color: '#494949',
  },
  infoTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoTableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoTableRowHeader: {
    fontSize: 13,
    fontWeight: 700,
  },
  infoTableCell: {
    fontSize: 13,
  },
  tableContainer: {
    marginTop: 20,
  },
  tableTitle: {
    fontSize: 14,
    marginBottom: 5,
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#E4E4E4',
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
});

export default ActivityReportRecap;
